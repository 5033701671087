import * as R from 'ramda'
import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import { RegistrationForm } from './registration-form'
import { Success } from './success'
import { Error } from './error'

const REGISTER = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        city
        company
        email
        firstName
        lastName
        phone
        state
        website
      }
    }
  }
`

const RegistrationStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  DUPLICATE: 'DUPLICATE',
}

export function Registration() {
  const [registrationStatus, setRegistrationStatus] = useState(null)

  const [register] = useMutation(REGISTER)

  const onSubmit = async (values) => {
    try {
      await register({
        variables: {
          input: {
            city: values.city,
            company: values.company,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            website: values.website,
            password: values.password,
            state: values.state,
          },
        },
      })

      setRegistrationStatus(RegistrationStatus.SUCCESS)
    } catch (e) {
      if (R.includes('duplicate', e.message)) {
        setRegistrationStatus(RegistrationStatus.DUPLICATE)
      } else {
        setRegistrationStatus(RegistrationStatus.ERROR)
      }
    }
  }

  return (
    <div>
      {registrationStatus === RegistrationStatus.SUCCESS ? <Success /> : null}
      {registrationStatus === RegistrationStatus.ERROR ? <Error /> : null}
      {registrationStatus === null ||
      registrationStatus === RegistrationStatus.DUPLICATE ? (
        <>
          <RegistrationForm
            onSubmit={onSubmit}
            duplicateMessage={
              registrationStatus === RegistrationStatus.DUPLICATE &&
              'Duplicate email address found.'
            }
          />
        </>
      ) : null}
    </div>
  )
}
