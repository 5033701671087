import React from 'react'

import { MainLayout } from '../../layouts/main'

import { ContactSidebarCard } from '../../components/contact-sidebar-card'
import { Registration } from '../../components/registration'
import { SectionTitle } from '../../components/section-title'

import * as styles from './index.module.css'

export default function RegistrationPage() {
  return (
    <MainLayout>
      <SectionTitle>User Registration</SectionTitle>
      <Content>
        <Aside>
          <ContactSidebarCard />
        </Aside>

        <Main>
          <>
            <div className={styles.formTitle}>Registration</div>
            <Registration />
          </>
        </Main>
      </Content>
    </MainLayout>
  )
}

const Aside = (props) => {
  return <aside className={styles.aside} {...props} />
}

const Content = (props) => {
  return <div className={styles.content} {...props} />
}

const Main = (props) => {
  return <div className={styles.main} {...props} />
}
