/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import * as yup from 'yup'

import * as styles from './registration-form.module.css'

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required().min(6),
  confirmPassword: yup.string().required(),
  company: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  phone: yup.string().required(),
})

// const devRegistrationUser = {
//   firstName: 'leo',
//   lastName: 'duran',
//   email: 'leoduran@gmail.com',
//   password: 'leo',
//   confirmPassword: 'aa',
//   company: 'Khanvis, LLC',
//   address: 'street, city, state',
//   city: 'SLC',
//   state: 'UT',
//   phone: '000-000-0000',
//   website: 'https://khanvis.com',
// }

const defaultUser = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  company: '',
  address: '',
  city: '',
  state: '',
  phone: '',
  website: '',
}

export const RegistrationForm = ({ duplicateMessage, onSubmit }) => {
  const [values, setValues] = useState(defaultUser)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleOnChange = (e) => {
    e.persist()

    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }))
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    try {
      await schema.validate(values)
      if (!isPasswordMismatch) {
        setErrorMessage(null)
        onSubmit(values)
      }
    } catch (err) {
      setErrorMessage(err.errors[0])
    }
  }

  const handleOnClick = (e) => {
    e.preventDefault()

    setValues(defaultUser)
  }

  const isPasswordMismatch = values.password !== values.confirmPassword

  return (
    <form className={styles.registrationForm} onSubmit={handleOnSubmit}>
      <div className={`${styles.formGroup}`}>
        <div className={styles.errorMessage}>{errorMessage}</div>
        <div className={styles.errorMessage}>{duplicateMessage}</div>
        <label className={`${styles.formLabel}`} htmlFor="company">
          Company
        </label>
        <input
          className={`${styles.formInput}`}
          type="text"
          id="company"
          name="company"
          value={values.company}
          onChange={handleOnChange}
        />
      </div>

      <div className={styles.fieldGroup}>
        <div className={`${styles.formState} ${styles.formGroup}`}>
          <label className={`${styles.formLabel}`} htmlFor="city">
            City
          </label>
          <input
            className={`${styles.formInput}`}
            type="text"
            id="city"
            name="city"
            value={values.city}
            onChange={handleOnChange}
          />
        </div>
        <div className={`${styles.formZip} ${styles.formGroup}`}>
          <label className={`${styles.formLabel}`} htmlFor="state">
            State
          </label>
          <select
            className={`${styles.formInput}`}
            type="text"
            id="state"
            name="state"
            value={values.state}
            onChange={handleOnChange}
          >
            <option>&nbsp;</option>
            <option value="AL">ALABAMA</option>
            <option value="AK">ALASKA</option>
            <option value="AS">AMERICAN SAMOA</option>
            <option value="AZ">ARIZONA</option>
            <option value="AR">ARKANSAS</option>
            <option value="CA">CALIFORNIA</option>
            <option value="CO">COLORADO</option>
            <option value="CT">CONNECTICUT</option>
            <option value="DE">DELAWARE</option>
            <option value="DC">DISTRICT OF COLUMBIA</option>
            <option value="FM">FEDERATED STATES OF MICRONESIA</option>
            <option value="FL">FLORIDA</option>
            <option value="GA">GEORGIA</option>
            <option value="GU">GUAM</option>
            <option value="HI">HAWAII</option>
            <option value="ID">IDAHO</option>
            <option value="IL">ILLINOIS</option>
            <option value="IN">INDIANA</option>
            <option value="IA">IOWA</option>
            <option value="KS">KANSAS</option>
            <option value="KY">KENTUCKY</option>
            <option value="LA">LOUISIANA</option>
            <option value="ME">MAINE</option>
            <option value="MH">MARSHALL ISLANDS</option>
            <option value="MD">MARYLAND</option>
            <option value="MA">MASSACHUSETTS</option>
            <option value="MI">MICHIGAN</option>
            <option value="MN">MINNESOTA</option>
            <option value="MS">MISSISSIPPI</option>
            <option value="MO">MISSOURI</option>
            <option value="MT">MONTANA</option>
            <option value="NE">NEBRASKA</option>
            <option value="NV">NEVADA</option>
            <option value="NH">NEW HAMPSHIRE</option>
            <option value="NJ">NEW JERSEY</option>
            <option value="NM">NEW MEXICO</option>
            <option value="NY">NEW YORK</option>
            <option value="NC">NORTH CAROLINA</option>
            <option value="ND">NORTH DAKOTA</option>
            <option value="MP">NORTHERN MARIANA ISLANDS</option>
            <option value="OH">OHIO</option>
            <option value="OK">OKLAHOMA</option>
            <option value="OR">OREGON</option>
            <option value="PW">PALAU</option>
            <option value="PA">PENNSYLVANIA</option>
            <option value="PR">PUERTO RICO</option>
            <option value="RI">RHODE ISLAND</option>
            <option value="SC">SOUTH CAROLINA</option>
            <option value="SD">SOUTH DAKOTA</option>
            <option value="TN">TENNESSEE</option>
            <option value="TX">TEXAS</option>
            <option value="UT">UTAH</option>
            <option value="VT">VERMONT</option>
            <option value="VI">VIRGIN ISLANDS</option>
            <option value="VA">VIRGINIA</option>
            <option value="WA">WASHINGTON</option>
            <option value="WV">WEST VIRGINIA</option>
            <option value="WI">WISCONSIN</option>
            <option value="WY">WYOMING</option>
          </select>
        </div>
      </div>

      <div className={`${styles.formGroup}`}>
        <label className={`${styles.formLabel}`} htmlFor="phone">
          Phone
        </label>
        <input
          className={`${styles.formInput}`}
          type="text"
          id="phone"
          name="phone"
          value={values.phone}
          onChange={handleOnChange}
        />
      </div>
      <div className={`${styles.formGroup}`}>
        <label className={`${styles.formLabel}`} htmlFor="website">
          Website
        </label>
        <input
          className={`${styles.formInput}`}
          type="text"
          id="website"
          name="website"
          value={values.website}
          onChange={handleOnChange}
        />
      </div>
      <div className={styles.fieldGroup}>
        <div className={`${styles.formFirstName} ${styles.formGroup}`}>
          <label className={`${styles.formLabel}`} htmlFor="firstName">
            First Name
          </label>
          <input
            className={`${styles.formInput}`}
            type="text"
            id="firstName"
            name="firstName"
            value={values.firstName}
            onChange={handleOnChange}
          />
        </div>
        <div className={`${styles.formLastName} ${styles.formGroup}`}>
          <label className={`${styles.formLabel}`} htmlFor="lastName">
            Last Name
          </label>
          <input
            className={`${styles.formInput}`}
            type="text"
            id="lastName"
            name="lastName"
            value={values.lastName}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className={`${styles.formGroup}`}>
        <label className={`${styles.formLabel}`} htmlFor="email">
          Email Address
        </label>
        <input
          className={`${styles.formInput} ${styles.email}`}
          type="text"
          id="email"
          name="email"
          value={values.email}
          onChange={handleOnChange}
        />
      </div>

      <div className={styles.fieldGroup}>
        <div className={`${styles.formFirstName} ${styles.formGroup}`}>
          <label className={`${styles.formLabel}`} htmlFor="password">
            Password
          </label>
          <input
            className={`${styles.formInput} ${
              isPasswordMismatch && styles.invalidField
            }`}
            type="password"
            id="password"
            name="password"
            value={values.password}
            onChange={handleOnChange}
          />
        </div>
        <div className={`${styles.formLastName} ${styles.formGroup}`}>
          <label className={`${styles.formLabel}`} htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            className={`${styles.formInput} ${
              isPasswordMismatch && styles.invalidField
            }`}
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleOnChange}
          />

          {isPasswordMismatch && (
            <div className={styles.passwordErrorMessage}>
              * Passwords do not match
            </div>
          )}
        </div>
      </div>

      <div className={styles.formButtons}>
        <div className={`${styles.formSubmitButton}`}>
          <button type="submit">Submit</button>
        </div>
        <div className={`${styles.formResetButton}`}>
          <button type="reset" onClick={handleOnClick}>
            Reset
          </button>
        </div>
      </div>
    </form>
  )
}

RegistrationForm.propTypes = { onSubmit: PropTypes.func.isRequired }
