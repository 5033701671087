import React from 'react'

export const Success = (props) => {
  return (
    <div>
      <h2>Registration Successful</h2>
      <p>
        Thank you for your submission. Please allow 2 business days for
        qualification.
      </p>
    </div>
  )
}

Success.propTypes = {}
