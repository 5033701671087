import React from 'react'

export const Error = (props) => {
  return (
    <div>
      <h2>Registration Error</h2>
      <p>Unable to register at this time. Please try again later.</p>
    </div>
  )
}

Error.propTypes = {}
